import { PropsWithChildren } from "react";
import styles from "./Panel.module.css";

type Props = PropsWithChildren<{
  title?: string;
}>;

const Panel = ({ title, children }: Props) => (
  <div className={styles.panel}>
    {title && <h2 className={styles.heading}>{title}</h2>}
    {children}
  </div>
);

export default Panel;
