import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import styles from "./DataCell.module.css";
import Input from "./Input";

interface Props {
  value: number | undefined;
  className?: string;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  saveChanges: (value: number | undefined) => void;
}

const DataCell = ({
  value,
  className,
  isDisabled = false,
  isHighlighted = false,
  saveChanges,
}: Props) => {
  const [inputValue, setInputValue] = useState(value);
  const isDirty = inputValue !== value;

  useEffect(() => {
    if (!isDirty) return;
    setInputValue(value);
  }, [value]);

  useBeforeUnload(
    useCallback(
      (e) => {
        if (!isDirty) return;
        e.preventDefault();
        e.returnValue = "";
      },
      [isDirty]
    )
  );

  return (
    <td
      className={classNames(styles.dataCell, className, {
        [styles.disabled]: isDisabled,
      })}
    >
      {!isDisabled && (
        <Input
          className={classNames(styles.input, {
            [styles.highlighted]: isHighlighted,
          })}
          inputMode="numeric"
          pattern="\d"
          value={inputValue ?? ""}
          onChange={(e) => setInputValue(processOptionalNumber(e.target.value))}
          onBlur={() => {
            if (!isDirty) return;
            saveChanges(inputValue);
          }}
        />
      )}
    </td>
  );
};

const processOptionalNumber = (s: string) => {
  const n = parseInt(s);
  return Number.isNaN(n) ? undefined : n;
};

export default DataCell;
