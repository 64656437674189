import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./Table.module.css";

interface Props {
  isSelectable?: boolean;
  total: number;
  head?: ReactNode;
  body: ReactNode;
}

const Table = ({ isSelectable = true, total, head, body }: Props) => (
  <div className={styles.wrapper}>
    <table
      className={classNames(styles.table, {
        [styles.selectable]: isSelectable,
      })}
    >
      {head && <thead>{head}</thead>}
      <tbody>{body}</tbody>
    </table>

    {total === 0 && (
      <div className={styles.status}>Keine Einträge gefunden.</div>
    )}
  </div>
);

export default Table;
