import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Tabs.module.css";

type Props = PropsWithChildren<{
  className?: string;
}>;

const Tabs = ({ className, children }: Props) => {
  return (
    <nav>
      <ul className={classNames(styles.tabs, className)}>{children}</ul>
    </nav>
  );
};

interface TabProps {
  text: string;
  isSelected: boolean;
  onClick?: () => void;
}

const Tab = ({ text, isSelected, onClick }: TabProps) => (
  <li
    className={classNames(styles.tab, { [styles.active]: isSelected })}
    onClick={onClick}
  >
    {text}
  </li>
);

Tabs.Tab = Tab;

export default Tabs;
