import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./global.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
