import { useState } from "react";
import { MD, parseErrorResponse } from "../http/monatsdatenApi";

const useFormError = <T, U>(
  onSubmit: (body: T) => Promise<U>,
  onClose: () => void
) => {
  const [error, setError] = useState<MD.ErrorResponse | undefined>();

  return {
    handleSubmit: (body: T, onSuccess: (res: U) => void) => {
      setError(undefined);
      onSubmit(body)
        .then(onSuccess)
        .catch((err) => setError(parseErrorResponse(err)));
    },
    handleClose: () => {
      setError(undefined);
      onClose();
    },
    error,
  };
};

export default useFormError;
