import { Arguments, SWRResponse } from "swr";
import { MD, UseMonthlyDataKey } from "./http/monatsdatenApi";
import { FormPeriod, RemoteDataStatus } from "./types";

export const range = (start: number, stop: number, step = 1): number[] => {
  const range: number[] = [];
  for (let i = start; i <= stop; i = i + step) {
    range.push(i);
  }
  return range;
};

export const months = range(0, 11);

export const getRemoteDataStatus = ({
  isValidating,
  error,
}: Partial<SWRResponse>): RemoteDataStatus => {
  if (isValidating) return "validating";
  if (error) return "failure";
  return "success";
};

export const parseIntParam = (param: string | null | undefined) => {
  if (!param) return;
  const parsed = parseInt(param);
  return !Number.isNaN(parsed) ? parsed : undefined;
};

export const formatAsNumber = (n: number) => n.toLocaleString(defaultLocale);

export const formatAsPercent = (fractionDigits: number, n: number): string =>
  n.toLocaleString(defaultLocale, {
    style: "percent",
    minimumFractionDigits: fractionDigits,
  });

const defaultLocale = "de-DE";

export const formatLongMonth = (date: Date) => {
  return date.toLocaleString(defaultLocale, {
    month: "long",
  });
};

export const getLongMonthName = (month: number) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(month);
  return formatLongMonth(date);
};

export const formatYearMonth = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}-${String(month).padStart(2, "0")}`;
};

export const formatISODate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0"
  )}`;
};

const formatLongYearMonth = (date: Date) => {
  return date.toLocaleString(defaultLocale, {
    month: "long",
    year: "numeric",
  });
};

export const periodToString = (
  { start, end }: MD.Period,
  opts?: { lowercase?: boolean }
) => {
  const formattedStart = formatLongYearMonth(new Date(start));
  const formattedEnd = end ? formatLongYearMonth(new Date(end)) : undefined;

  return formattedEnd
    ? `${formattedStart} – ${formattedEnd}`
    : `${opts?.lowercase ? "ab" : "Ab"} ${formattedStart}`;
};

export const getLastOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const compareDates = (a: Date, b: Date) => {
  a.setHours(0, 0, 0, 0);
  b.setHours(0, 0, 0, 0);
  return a.getTime() - b.getTime();
};

const compareYears = (dateStr: string, year: number) =>
  new Date(dateStr).getFullYear() - year;

export const includesYear = ({ start, end }: MD.Period, year: number) => {
  const afterStart = compareYears(start, year) <= 0;
  const beforeEnd = !end || compareYears(end, year) >= 0;
  return afterStart && beforeEnd;
};

export const byStartDateAsc = (
  { period: a }: MD.Version,
  { period: b }: MD.Version
) => compareDates(new Date(a.start), new Date(b.start));

export const byStartDateDesc = (
  { period: a }: MD.Version,
  { period: b }: MD.Version
) => compareDates(new Date(b.start), new Date(a.start));

export const removeDuplicates = <T>(array: T[]): T[] => [...new Set(array)];

export const upsertArray = <T>(
  array: T[],
  elem: T,
  checkIsElementToUpdate: (elem: T) => boolean
): T[] => [...array.filter((x) => !checkIsElementToUpdate(x)), elem];

export const getMinOptionalDate = (
  a: string | undefined,
  b: string | undefined
) => {
  if (!a) return b;
  if (!b) return a;
  return compareDates(new Date(a), new Date(b)) < 0 ? a : b;
};

export const getMaxOptionalDate = (
  a: string | undefined,
  b: string | undefined
) => {
  if (!a) return b;
  if (!b) return a;
  return compareDates(new Date(a), new Date(b)) < 0 ? b : a;
};

export const processFormPeriod = (period: FormPeriod): MD.Period => {
  const start = new Date(period.start);
  const end =
    period.end && period.end !== ""
      ? getLastOfMonth(new Date(period.end))
      : undefined;

  return {
    start: formatISODate(start),
    end: end ? formatISODate(end) : undefined,
  };
};

export const isUseMonthlyDataKey = (
  key: Arguments
): key is UseMonthlyDataKey => {
  const cast = key as UseMonthlyDataKey;
  return cast?.url !== undefined && cast?.query?.year !== undefined;
};
