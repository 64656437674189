import useFormError from "../hooks/useFormError";
import { MD } from "../http/monatsdatenApi";
import { FormPeriod } from "../types";
import { formatYearMonth, processFormPeriod } from "../utils";
import VersionForm, { VersionFormData } from "./VersionForm";
import ModalDialog from "./ui/ModalDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  version: MD.Version;
  minDate?: string;
  maxDate?: string;
  onSubmit: (body: MD.PutVersionBody) => Promise<MD.Version>;
  onSuccess: (edited: MD.Version) => void;
}

const EditVersionModal = ({
  isOpen,
  onClose,
  version,
  minDate,
  maxDate,
  onSubmit,
  onSuccess,
}: Props) => {
  const { handleSubmit, handleClose, error } = useFormError(onSubmit, onClose);

  return (
    <ModalDialog
      title="Version bearbeiten"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <VersionForm
        defaultValues={{
          type: version.type,
          period: toFormPeriod(version.period),
        }}
        error={error}
        minDate={minDate}
        maxDate={maxDate}
        showCompanyTypeSelect={false}
        onSubmit={(formData) =>
          handleSubmit(processFormData(formData), onSuccess)
        }
        onAbort={handleClose}
      />
    </ModalDialog>
  );
};

const toFormPeriod = ({ start, end }: MD.Period): FormPeriod => ({
  start: start ? formatYearMonth(new Date(start)) : "",
  end: end ? formatYearMonth(new Date(end)) : "",
});

const processFormData = ({ period }: VersionFormData): MD.PutVersionBody => ({
  period: processFormPeriod(period),
});

export default EditVersionModal;
