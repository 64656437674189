import { Link, useParams } from "react-router-dom";
import monatsdatenSVG from "../assets/monatsdaten.svg";
import AccountButton from "./AccountButton";
import CompanySelect from "./CompanySelect";
import styles from "./Header.module.css";

const Header = () => {
  const { companyId } = useParams();

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <Link to={companyId ? `/${companyId}` : "/"} className={styles.logo}>
          <img src={monatsdatenSVG} alt="HGV Monatsdaten" />
        </Link>
        <CompanySelect />
      </div>
      <AccountButton />
    </header>
  );
};

export default Header;
