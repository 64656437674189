import { PropsWithChildren, useRef } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ModalRoot from "../ModalRoot";
import SvgClose from "../icons/Close";
import Button, { ButtonType } from "./Button";
import Icon from "./Icon";
import styles from "./ModalDialog.module.css";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actions?: ActionButton[];
}>;

interface ActionButton {
  title: string;
  type: ButtonType;
  isLoading?: boolean;
  onClick: () => void;
}

const ModalDialog = ({ children, isOpen, onClose, title, actions }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const open = () => !dialogRef.current?.open && dialogRef.current?.showModal();
  const close = () => dialogRef.current?.open && dialogRef.current?.close();

  isOpen ? open() : close();

  useOnClickOutside(contentRef, close);

  return (
    <ModalRoot>
      <dialog
        ref={dialogRef}
        className={styles.dialog}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
      >
        <div ref={contentRef} className={styles.content}>
          <header className={styles.header}>
            <div className={styles.title}>{title}</div>
            <button className={styles.closeButton} onClick={close}>
              <Icon className={styles.icon} glyph={SvgClose} />
            </button>
          </header>

          {/* this condition causes the reset of the children's state on close */}
          {isOpen && children}

          {actions && (
            <footer className={styles.buttons}>
              {actions.map(({ title, type, isLoading, onClick }, index) => (
                <Button
                  key={index}
                  type={type}
                  isLoading={isLoading}
                  buttonProps={{
                    onClick,
                  }}
                >
                  {title}
                </Button>
              ))}
            </footer>
          )}
        </div>
      </dialog>
    </ModalRoot>
  );
};

export default ModalDialog;
