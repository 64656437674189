import { PropsWithChildren, ReactNode, useEffect } from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import SvgBackArrow from "../icons/BackArrow";
import Icon from "./Icon";
import styles from "./Page.module.css";

type Props = PropsWithChildren<{
  title?: string;
  description?: ReactNode;
  actions?: ReactNode;
  backLinkText?: string;
}>;

const Page = ({
  title,
  children,
  description,
  actions,
  backLinkText,
}: Props) => {
  useEffect(() => {
    document.title = `${title ? `${title} | ` : ""}${i18n.title}`;
  }, [title]);

  return (
    <main className={styles.page}>
      <div className={styles.content}>
        {backLinkText && (
          <NavLink className={styles.backLink} to="..">
            <Icon glyph={SvgBackArrow} className={styles.icon} />
            {backLinkText}
          </NavLink>
        )}
        <div className={styles.header}>
          <div className={styles.headerTop}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      </div>

      {children}
    </main>
  );
};

export default Page;
