import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Input.module.css";

type Props = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ isInvalid, ...props }, ref) => {
    const { type = "text", autoComplete = "off", spellCheck = "false" } = props;
    return (
      <input
        ref={ref}
        {...props}
        type={type}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        className={classNames(styles.input, props.className, {
          [styles.invalid]: isInvalid,
        })}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
