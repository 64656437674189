import { Route, Routes } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useCompany } from "../context/companyContext";
import { Permission } from "../types";
import DataPage from "./DataPage";
import Header from "./Header";
import styles from "./Home.module.css";
import Settings from "./Settings";
import ErrorText from "./ui/ErrorText";

const Home = () => {
  const auth = useAuth();
  const { company, status } = useCompany();
  const canWriteSettings = company?.permissions.includes(
    Permission.SettingsWrite
  );

  if (!auth.session) return null;

  return (
    <>
      <Header />

      {status === "success" && company && (
        <Routes>
          <Route index element={<DataPage companyId={company.id} />} />
          {canWriteSettings && (
            <Route path="einstellungen/*">
              <Route index element={<Settings companyId={company.id} />} />
            </Route>
          )}
        </Routes>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der Betriebsdaten." />
        </div>
      )}
    </>
  );
};

export default Home;
