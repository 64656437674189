import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { mutate as globalMutate } from "swr";
import NotificationDispatch, {
  showErrorNotification,
} from "../context/notificationContext";
import {
  MD,
  dismissAlert,
  getUseMonthlyDataKey,
  putMonthlyData,
  useMonthlyData,
} from "../http/monatsdatenApi";
import i18n from "../i18n";
import {
  compareDates,
  getRemoteDataStatus,
  isUseMonthlyDataKey,
  periodToString,
  range,
  upsertArray,
} from "../utils";
import LodgingTable from "./LodgingTable";
import NonLodgingTable from "./NonLodgingTable";
import styles from "./VersionData.module.css";
import Alert from "./ui/Alert";
import ErrorText from "./ui/ErrorText";
import LoadingSpinner from "./ui/LoadingSpinner";
import Panel from "./ui/Panel";

interface Props {
  companyId: MD.CompanyId;
  version: MD.Version;
  year: number;
}

export type ExternalOpenDaysSourceByMonth = {
  [month: number]: MD.ExternalOpenDaysSource;
};

const VersionData = ({ companyId, version, year }: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const activeMonths = getActiveMonths(version.period, year);

  const {
    data,
    isValidating,
    error,
    mutate: mutateMonthlyData,
  } = useMonthlyData(companyId, version.number, year);
  const monthlyData = data?.items ?? [];
  const status = getRemoteDataStatus({ isValidating, error });

  const externalOpenDaysSourcesByMonth = data?.externalOpenDaysSources?.reduce(
    (state: ExternalOpenDaysSourceByMonth, value) => {
      getActiveMonths(value.period, year).forEach(
        (m) => (state[m] = value.source)
      );
      return state;
    },
    {}
  );

  const copiedFacilities = getCopiedFacilities(monthlyData);
  const showAlert = copiedFacilities.length > 0 && !data?.alertDismissed;

  const invalidateCache = (year: number) =>
    globalMutate(
      (args) => {
        const keyToMatch = getUseMonthlyDataKey(
          companyId,
          version.number,
          year
        );
        return (
          isUseMonthlyDataKey(args) &&
          args.url === keyToMatch.url &&
          args.query.year === keyToMatch.query.year
        );
      },
      undefined,
      { revalidate: true }
    );

  const onChange = (
    rawBody: MD.PutMonthlyDataBody,
    setIsSubmitting: (value: boolean) => void
  ) => {
    setIsSubmitting(true);

    const previousEntry = monthlyData.find(
      (md) => compareDates(new Date(md.date), new Date(rawBody.date)) === 0
    );
    const optimisticEntry: MD.MonthlyData = {
      ...previousEntry,
      date: rawBody.date,
      ...(rawBody.lodgingData && {
        lodgingData: {
          ...previousEntry?.lodgingData,
          ...rawBody.lodgingData,
          ...(rawBody.lodgingData?.adultBeds !==
            previousEntry?.lodgingData?.adultBeds && {
            adultBedsSrc:
              rawBody.lodgingData?.adultBeds !== undefined ? "user" : undefined,
          }),
          ...(rawBody.lodgingData?.extraBeds !==
            previousEntry?.lodgingData?.extraBeds && {
            extraBedsSrc:
              rawBody.lodgingData?.extraBeds !== undefined ? "user" : undefined,
          }),
        },
      }),
      ...(rawBody.nonLodgingData && {
        nonLodgingData: {
          ...previousEntry?.nonLodgingData,
          ...rawBody.nonLodgingData,
          ...(rawBody.nonLodgingData?.seats !==
            previousEntry?.nonLodgingData?.seats && {
            seatsSrc:
              rawBody.nonLodgingData?.seats !== undefined ? "user" : undefined,
          }),
        },
      }),
      ...(rawBody.campingData && {
        campingData: {
          ...previousEntry?.campingData,
          ...rawBody.campingData,
          ...(rawBody.campingData?.pitches !==
            previousEntry?.campingData?.pitches && {
            pitchesSrc:
              rawBody.campingData?.pitches !== undefined ? "user" : undefined,
          }),
        },
      }),
      ...(rawBody.overnightStays && {
        overnightStays: {
          ...previousEntry?.overnightStays,
          ...rawBody.overnightStays,
        },
      }),
    };

    const optimisticData = {
      ...data,
      items: upsertArray(
        monthlyData,
        optimisticEntry,
        (elem) =>
          compareDates(new Date(elem.date), new Date(rawBody.date)) === 0
      ),
    };

    // Open days must be omitted from the request body if they are synced from an external source
    const processedBody: MD.PutMonthlyDataBody =
      externalOpenDaysSourcesByMonth?.[new Date(rawBody.date).getMonth()]
        ? {
            ...rawBody,
            ...(rawBody.lodgingData && {
              lodgingData: { ...rawBody.lodgingData, openDays: undefined },
            }),
            ...(rawBody.nonLodgingData && {
              nonLodgingData: {
                ...rawBody.nonLodgingData,
                openDays: undefined,
              },
            }),
            ...(rawBody.campingData && {
              campingData: { ...rawBody.campingData, openDays: undefined },
            }),
          }
        : rawBody;

    mutateMonthlyData(
      putMonthlyData(companyId, version.number, processedBody)
        .then(() => {
          invalidateCache(year + 1);
          return optimisticData;
        })
        .catch((err) => {
          dispatch(showErrorNotification(err));
          return data;
        })
        .finally(() => setIsSubmitting(false)),
      {
        optimisticData,
        rollbackOnError: true,
        revalidate: false,
      }
    );
  };

  return (
    <>
      <Panel>
        <div key={version.number} className={styles.versionInfo}>
          <div>
            <Label>Betriebsart</Label>
            {i18n.getCompanyType(version.type)}
          </div>
          <div>
            <Label>Zeitraum</Label>
            {periodToString(version.period)}
          </div>
        </div>
      </Panel>

      {status === "success" && (
        <>
          {showAlert && (
            <Alert
              onDismiss={() => {
                dismissAlert(companyId, version.number, year);
                mutateMonthlyData(
                  { items: monthlyData, alertDismissed: true },
                  { revalidate: false }
                );
              }}
            >
              Die {concatGermanList(copiedFacilities)} wurden vom Vorjahr
              übernommen. Bitte kontrollieren Sie, ob die Daten noch aktuell
              sind.
            </Alert>
          )}

          {(hasLodgingData(version.type) || hasCampingData(version.type)) && (
            <Article title="Beherbergung">
              {(setIsSubmitting) => (
                <LodgingTable
                  type={version.type}
                  year={year}
                  activeMonths={activeMonths}
                  monthlyData={monthlyData}
                  externalOpenDaysSources={externalOpenDaysSourcesByMonth}
                  highlightCopiedFacilities={showAlert}
                  onChange={(body) => onChange(body, setIsSubmitting)}
                />
              )}
            </Article>
          )}

          {hasNonLodgingData(version.type) && (
            <Article title="Nichtbeherbergung">
              {(setIsSubmitting) => (
                <NonLodgingTable
                  year={year}
                  activeMonths={activeMonths}
                  monthlyData={monthlyData}
                  externalOpenDaysSources={externalOpenDaysSourcesByMonth}
                  highlightCopiedFacilities={showAlert}
                  onChange={(body) => onChange(body, setIsSubmitting)}
                />
              )}
            </Article>
          )}
        </>
      )}

      {status === "validating" && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der Monatsdaten." />
        </div>
      )}
    </>
  );
};

interface ArticleProps {
  title: string;
  children?:
    | ReactNode
    | ((setIsSubmitting: Dispatch<SetStateAction<boolean>>) => ReactNode);
}

const Article = ({ title, children }: ArticleProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <article>
      <div className={styles.articleHeading}>
        <h2>{title}</h2>
        {isSubmitting && <LoadingSpinner size="small" />}
      </div>
      {typeof children === "function" ? children(setIsSubmitting) : children}
    </article>
  );
};

const Label = ({ children }: PropsWithChildren) => (
  <p className={styles.label}>{children}</p>
);

const hasLodgingData = (type: MD.CompanyType) =>
  type === "pure-lodging" || type === "mixed-business";

const hasNonLodgingData = (type: MD.CompanyType) =>
  type === "pure-non-lodging" || type === "mixed-business";

const hasCampingData = (type: MD.CompanyType) => type === "camping";

const getCopiedFacilities = (monthlyData: MD.MonthlyData[]) =>
  monthlyData
    .reduce((state: string[], value) => {
      const beds = "Betten";
      const seats = "Sitzplätze";
      const pitches = "Stellplätze";

      if (
        (value.lodgingData?.adultBedsSrc === "system" ||
          value.lodgingData?.extraBedsSrc === "system") &&
        !state.includes(beds)
      )
        state.push(beds);

      if (value.nonLodgingData?.seatsSrc === "system" && !state.includes(seats))
        state.push(seats);

      if (
        value.campingData?.pitchesSrc === "system" &&
        !state.includes(pitches)
      )
        state.push(pitches);

      return state;
    }, [])
    .sort((a, b) => a.localeCompare(b));

const concatGermanList = (list: string[]) => {
  switch (list.length) {
    case 0:
      return "";
    case 1:
      return list[0];
    default:
      const allButLast = list.slice(0, -1);
      const last = list[list.length - 1];
      return `${allButLast.join(", ")} und ${last}`;
  }
};

const getActiveMonths = (period: MD.Period, year: number) => {
  const start = new Date(period.start);
  const end = period.end ? new Date(period.end) : undefined;

  if (start.getFullYear() > year || (end && end.getFullYear() < year))
    return [];

  return range(
    start.getFullYear() === year ? start.getMonth() : 0,
    end && end.getFullYear() === year ? end.getMonth() : 11
  );
};

export default VersionData;
