import { FunctionComponent, SVGProps } from "react";

export type Glyph = FunctionComponent<SVGProps<SVGSVGElement>>;

export type RemoteDataStatus = "success" | "loading" | "validating" | "failure";

interface ListFilters {
  page: number;
  limit: number;
  q?: string;
}

export type PaginationQuery<T = object> = T & Partial<ListFilters>;

export interface FetcherOptions {
  url: string;
  query?: object;
  signal?: AbortSignal;
}

export interface FormPeriod {
  start: string;
  end: string;
}

export enum Permission {
  DataWrite = "monatsdaten.data:write",
  SettingsWrite = "monatsdaten.settings:write",
}
