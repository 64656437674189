import classNames from "classnames";
import { PropsWithChildren } from "react";
import { MD } from "../http/monatsdatenApi";
import { formatISODate, getLongMonthName, months } from "../utils";
import styles from "./LodgingTable.module.css";
import OpenDaysDataCell from "./OpenDaysDataCell";
import { ExternalOpenDaysSourceByMonth } from "./VersionData";
import CalculatedDataCell from "./ui/CalculatedDataCell";
import DataCell from "./ui/DataCell";
import DataTable from "./ui/DataTable";

interface Props {
  type: MD.CompanyType;
  year: number;
  activeMonths: number[];
  monthlyData: MD.MonthlyData[];
  externalOpenDaysSources?: ExternalOpenDaysSourceByMonth;
  highlightCopiedFacilities?: boolean;
  onChange: (body: MD.PutMonthlyDataBody) => void;
}

interface OvernightStaysWithSums extends MD.OvernightStaysWithPreviousYearSum {
  byGuestTypeSum?: number;
  byBoardSum?: number;
}

type MonthlyDataWithSums = Omit<MD.MonthlyData, "overnightStays"> & {
  overnightStays: OvernightStaysWithSums;
};

type Totals = {
  [key in keyof OvernightStaysWithSums]?: number;
};

const LodgingTable = ({
  type,
  year,
  activeMonths,
  monthlyData: rawMonthlyData,
  externalOpenDaysSources,
  highlightCopiedFacilities = false,
  onChange,
}: Props) => {
  const { monthlyDataByMonth, totals } = rawMonthlyData.reduce(
    (
      state: {
        monthlyDataByMonth: {
          [month: number]: MonthlyDataWithSums;
        };
        totals: Totals;
      },
      value
    ) => {
      const { overnightStays } = value;
      const month = new Date(value.date).getMonth();

      const byGuestTypeSum = sumOptionalNumbers([
        overnightStays?.individualGuests,
        overnightStays?.mediatedGuests,
        overnightStays?.guestsWithDiscount,
        overnightStays?.children,
        overnightStays?.others,
      ]);

      const byBoardSum = sumOptionalNumbers([
        overnightStays?.overnights,
        overnightStays?.bedAndBreakfast,
        overnightStays?.halfBoard,
        overnightStays?.fullBoard,
      ]);

      state.monthlyDataByMonth[month] = {
        ...value,
        overnightStays: {
          ...overnightStays,
          byGuestTypeSum,
          byBoardSum,
        },
      };

      state.totals.individualGuests = sumOptionalNumbers([
        state.totals.individualGuests,
        overnightStays?.individualGuests,
      ]);
      state.totals.mediatedGuests = sumOptionalNumbers([
        state.totals.mediatedGuests,
        overnightStays?.mediatedGuests,
      ]);
      state.totals.guestsWithDiscount = sumOptionalNumbers([
        state.totals.guestsWithDiscount,
        overnightStays?.guestsWithDiscount,
      ]);
      state.totals.children = sumOptionalNumbers([
        state.totals.children,
        overnightStays?.children,
      ]);
      state.totals.others = sumOptionalNumbers([
        state.totals.others,
        overnightStays?.others,
      ]);
      state.totals.adultsFree = sumOptionalNumbers([
        state.totals.adultsFree,
        overnightStays?.adultsFree,
      ]);
      state.totals.childrenWithDiscount = sumOptionalNumbers([
        state.totals.childrenWithDiscount,
        overnightStays?.childrenWithDiscount,
      ]);
      state.totals.overnights = sumOptionalNumbers([
        state.totals.overnights,
        overnightStays?.overnights,
      ]);
      state.totals.bedAndBreakfast = sumOptionalNumbers([
        state.totals.bedAndBreakfast,
        overnightStays?.bedAndBreakfast,
      ]);
      state.totals.halfBoard = sumOptionalNumbers([
        state.totals.halfBoard,
        overnightStays?.halfBoard,
      ]);
      state.totals.fullBoard = sumOptionalNumbers([
        state.totals.fullBoard,
        overnightStays?.fullBoard,
      ]);
      state.totals.byGuestTypeSum = sumOptionalNumbers([
        state.totals.byGuestTypeSum,
        byGuestTypeSum,
      ]);
      state.totals.byBoardSum = sumOptionalNumbers([
        state.totals.byBoardSum,
        byBoardSum,
      ]);
      state.totals.previousYearSum = sumOptionalNumbers([
        state.totals.previousYearSum,
        overnightStays?.previousYearSum,
      ]);

      return state;
    },
    { monthlyDataByMonth: {}, totals: {} }
  );

  return (
    <DataTable
      classNames={{ table: styles.table }}
      head={
        <tr>
          <th></th>
          <th></th>
          {months.map((m) => (
            <th key={m} className={styles.month}>
              {getLongMonthName(m)}
            </th>
          ))}
          <th>Insgesamt</th>
          <th>Anteil</th>
        </tr>
      }
    >
      {hasLodgingData(type) && (
        <>
          <RowGroup>
            <tr>
              <RowGroupHeader rowSpan={2}>Betten</RowGroupHeader>
              <RowHeader>Erwachsenenbetten</RowHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <DataCell
                    key={m}
                    className={styles.month}
                    isDisabled={!activeMonths.includes(m)}
                    isHighlighted={
                      highlightCopiedFacilities &&
                      md?.lodgingData?.adultBedsSrc === "system"
                    }
                    value={md?.lodgingData?.adultBeds}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        lodgingData: {
                          ...md?.lodgingData,
                          adultBeds: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell />
              <CalculatedDataCell />
            </tr>
            <tr>
              <RowHeader>Zustellbetten</RowHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <DataCell
                    key={m}
                    className={styles.month}
                    isDisabled={!activeMonths.includes(m)}
                    isHighlighted={
                      highlightCopiedFacilities &&
                      md?.lodgingData?.extraBedsSrc === "system"
                    }
                    value={md?.lodgingData?.extraBeds}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        lodgingData: {
                          ...md?.lodgingData,
                          extraBeds: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell />
              <CalculatedDataCell />
            </tr>
          </RowGroup>

          <RowGroup showSeparator={true}>
            <tr>
              <RowGroupHeader colSpan={2}>Offenhaltetage</RowGroupHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <OpenDaysDataCell
                    key={m}
                    className={styles.month}
                    source={externalOpenDaysSources?.[m]}
                    value={md?.lodgingData?.openDays}
                    isActive={activeMonths.includes(m)}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        lodgingData: {
                          ...md?.lodgingData,
                          openDays: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell
                value={sumOptionalNumbers(
                  rawMonthlyData.map((md) => md.lodgingData?.openDays)
                )}
              />
              <CalculatedDataCell />
            </tr>
          </RowGroup>
        </>
      )}

      {hasCampingData(type) && (
        <>
          <RowGroup>
            <tr>
              <RowGroupHeader rowSpan={2}>Stellplätze</RowGroupHeader>
              <RowHeader>Vorhanden</RowHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <DataCell
                    key={m}
                    className={styles.month}
                    isDisabled={!activeMonths.includes(m)}
                    isHighlighted={
                      highlightCopiedFacilities &&
                      md?.campingData?.pitchesSrc === "system"
                    }
                    value={md?.campingData?.pitches}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        campingData: {
                          ...md?.campingData,
                          pitches: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell />
              <CalculatedDataCell />
            </tr>
            <tr>
              <RowHeader>Vermietet</RowHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <DataCell
                    key={m}
                    className={styles.month}
                    isDisabled={!activeMonths.includes(m)}
                    value={md?.campingData?.leasedPitches}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        campingData: {
                          ...md?.campingData,
                          leasedPitches: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell />
              <CalculatedDataCell />
            </tr>
          </RowGroup>

          <RowGroup showSeparator={true}>
            <tr>
              <RowGroupHeader colSpan={2}>Offenhaltetage</RowGroupHeader>
              {months.map((m) => {
                const md = monthlyDataByMonth[m];
                return (
                  <OpenDaysDataCell
                    key={m}
                    className={styles.month}
                    source={externalOpenDaysSources?.[m]}
                    value={md?.campingData?.openDays}
                    isActive={activeMonths.includes(m)}
                    saveChanges={(value) =>
                      onChange({
                        ...md,
                        date: formatISODate(new Date(year, m)),
                        campingData: {
                          ...md?.campingData,
                          openDays: value,
                        },
                      })
                    }
                  />
                );
              })}
              <CalculatedDataCell
                value={sumOptionalNumbers(
                  rawMonthlyData.map((md) => md.campingData?.openDays)
                )}
              />
              <CalculatedDataCell />
            </tr>
          </RowGroup>
        </>
      )}

      <RowGroup>
        <tr>
          <RowGroupHeader rowSpan={6}>
            Nächtigungen nach Art der Gäste
          </RowGroupHeader>
          <RowHeader tooltip="Individualgäste, welche direkt gebucht haben">
            Individualgäste
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.individualGuests}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      individualGuests: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.individualGuests} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.individualGuests && !!totals.byGuestTypeSum
                ? totals.individualGuests / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader tooltip="Gäste, für welche man Dritten für die Vermittlung eine Provision schuldet (z.B. Reiseagenturen, Touroperators, Booking Südtirol, Booking.com, usw.)">
            Vermittelte Gäste
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.mediatedGuests}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      mediatedGuests: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.mediatedGuests} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.mediatedGuests && !!totals.byGuestTypeSum
                ? totals.mediatedGuests / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader tooltip="Gäste mit einem Spezialtarif (z.B. Arbeiter, Jugendgruppen, andere Gruppen usw.)">
            Gäste mit Preisnachlass
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.guestsWithDiscount}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      guestsWithDiscount: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.guestsWithDiscount} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.guestsWithDiscount && !!totals.byGuestTypeSum
                ? totals.guestsWithDiscount / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader tooltip="Wie Ortstaxe < 14 Jahre">Kinder</RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.children}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      children: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.children} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.children && !!totals.byGuestTypeSum
                ? totals.children / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Sonstige</RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.others}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      others: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.others} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.others && !!totals.byGuestTypeSum
                ? totals.others / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Gesamtsumme</RowHeader>
          {months.map((m) => (
            <CalculatedDataCell
              key={m}
              className={styles.month}
              value={monthlyDataByMonth[m]?.overnightStays?.byGuestTypeSum}
            />
          ))}
          <CalculatedDataCell value={totals.byGuestTypeSum} />
          <CalculatedDataCell
            format="percentage"
            value={totals.byGuestTypeSum ? 1 : undefined}
          />
        </tr>
      </RowGroup>

      <RowGroup showSeparator={true}>
        <tr>
          <RowGroupHeader rowSpan={2}>Davon</RowGroupHeader>
          <RowHeader tooltip="Gratisgäste wie Reisebegleiter von Gruppen, Busfahrer usw. (Achtung: auch für diese besteht die Pflicht zur Ausstellung eines Steuerbeleges mit dem Normalwert)">
            Erwachsene (gratis)
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.adultsFree}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      adultsFree: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.adultsFree} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.adultsFree && !!totals.byGuestTypeSum
                ? totals.adultsFree / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader tooltip="Gratiskinder oder Kinder, welche nur 50 % oder noch weniger des Normaltarifes zahlen. Diese verbessern das Ergebnis der Branchenstudie, weshalb man diese Nächtigungen unbedingt ermitteln sollte.">
            Kinder mit Nachlass &gt; 49&nbsp;%
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.childrenWithDiscount}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      childrenWithDiscount: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.childrenWithDiscount} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.childrenWithDiscount && !!totals.byGuestTypeSum
                ? totals.childrenWithDiscount / totals.byGuestTypeSum
                : undefined
            }
          />
        </tr>
      </RowGroup>

      <RowGroup showSeparator={true}>
        <tr>
          <RowGroupHeader rowSpan={5}>
            Nächtigungen nach Art der erbrachten Leistung
          </RowGroupHeader>
          <RowHeader tooltip="Inklusive Ferienwohnungen">
            Nacht ohne Frühstück
          </RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.overnights}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      overnights: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.overnights} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.overnights && !!totals.byBoardSum
                ? totals.overnights / totals.byBoardSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Nacht mit Frühstück</RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.bedAndBreakfast}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      bedAndBreakfast: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.bedAndBreakfast} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.bedAndBreakfast && !!totals.byBoardSum
                ? totals.bedAndBreakfast / totals.byBoardSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Halbpension</RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.halfBoard}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      halfBoard: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.halfBoard} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.halfBoard && !!totals.byBoardSum
                ? totals.halfBoard / totals.byBoardSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Vollpension</RowHeader>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                isDisabled={!activeMonths.includes(m)}
                value={md?.overnightStays?.fullBoard}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    overnightStays: {
                      ...md?.overnightStays,
                      fullBoard: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.fullBoard} />
          <CalculatedDataCell
            format="percentage"
            value={
              totals.fullBoard && !!totals.byBoardSum
                ? totals.fullBoard / totals.byBoardSum
                : undefined
            }
          />
        </tr>
        <tr>
          <RowHeader>Kontrollsumme</RowHeader>
          {months.map((m) => {
            const os = monthlyDataByMonth[m]?.overnightStays;
            const isInvalid =
              os?.byGuestTypeSum !== undefined &&
              os?.byBoardSum !== undefined &&
              os?.byGuestTypeSum !== os?.byBoardSum;
            return (
              <CalculatedDataCell
                key={m}
                className={styles.month}
                value={os?.byBoardSum}
                isInvalid={isInvalid}
                title={
                  isInvalid
                    ? "Die Kontrollsumme stimmt nicht mit der Gesamtsumme überein."
                    : undefined
                }
              />
            );
          })}
          <CalculatedDataCell value={totals.byBoardSum} />
          <CalculatedDataCell
            format="percentage"
            value={totals.byBoardSum ? 1 : undefined}
          />
        </tr>
      </RowGroup>

      <RowGroup>
        <tr>
          <RowGroupHeader rowSpan={2}>Jahresvergleich</RowGroupHeader>
          <RowHeader>Nächtigungen im Vorjahr</RowHeader>
          {months.map((m) => (
            <CalculatedDataCell
              key={m}
              className={styles.month}
              value={monthlyDataByMonth[m]?.overnightStays?.previousYearSum}
            />
          ))}
          <CalculatedDataCell value={totals.previousYearSum} />
          <CalculatedDataCell />
        </tr>
        <tr>
          <RowHeader>Veränderung zum Vorjahr</RowHeader>
          {months.map((m) => {
            const byGuestTypeSum =
              monthlyDataByMonth[m]?.overnightStays?.byGuestTypeSum;
            const byBoardSum =
              monthlyDataByMonth[m]?.overnightStays?.byBoardSum;
            const currentYearSum =
              byGuestTypeSum || byBoardSum
                ? Math.max(byGuestTypeSum ?? 0, byBoardSum ?? 0)
                : undefined;
            const previousYearSum =
              monthlyDataByMonth[m]?.overnightStays?.previousYearSum;
            return (
              <CalculatedDataCell
                key={m}
                className={styles.month}
                format="percentage"
                value={
                  currentYearSum && previousYearSum
                    ? calcDeviation(currentYearSum, previousYearSum)
                    : undefined
                }
              />
            );
          })}
          <CalculatedDataCell
            format="percentage"
            value={
              (totals.byGuestTypeSum || totals.byBoardSum) &&
              totals.previousYearSum
                ? calcDeviation(
                    Math.max(
                      totals.byGuestTypeSum ?? 0,
                      totals.byBoardSum ?? 0
                    ),
                    totals.previousYearSum
                  )
                : undefined
            }
          />
          <CalculatedDataCell />
        </tr>
      </RowGroup>
    </DataTable>
  );
};

type RowGroupProps = PropsWithChildren<{ showSeparator?: boolean }>;

const RowGroup = ({ showSeparator = false, children }: RowGroupProps) => (
  <tbody className={classNames({ [styles.withSeparator]: showSeparator })}>
    {children}
  </tbody>
);

type RowGroupHeaderProps = PropsWithChildren<{
  rowSpan?: number;
  colSpan?: number;
}>;

const RowGroupHeader = ({
  rowSpan,
  colSpan,
  children,
}: RowGroupHeaderProps) => (
  <th className={styles.rowGroupHeader} rowSpan={rowSpan} colSpan={colSpan}>
    {children}
  </th>
);

type RowHeaderProps = PropsWithChildren<{
  tooltip?: string;
}>;

const RowHeader = ({ tooltip, children }: RowHeaderProps) => (
  <th className={classNames({ [styles.tooltip]: tooltip })} title={tooltip}>
    {children}
  </th>
);

const hasLodgingData = (type: MD.CompanyType) =>
  type === "pure-lodging" || type === "mixed-business";

const hasCampingData = (type: MD.CompanyType) => type === "camping";

const sumOptionalNumbers = (array: (number | undefined | null)[]) =>
  array.reduce((state: number | undefined, value) => {
    if (value === undefined || value === null) return state;
    return (state ?? 0) + value;
  }, undefined);

const calcDeviation = (current: number, previous: number) =>
  (current - previous) / previous;

export default LodgingTable;
