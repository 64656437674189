import classNames from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Glyph } from "../../types";
import styles from "./Button.module.css";
import Icon from "./Icon";
import LoadingSpinner, { Theme } from "./LoadingSpinner";

export type ButtonType = "primary" | "secondary" | "destructive";

type ButtonSize = "small" | "default";

type Props = PropsWithChildren<{
  type?: ButtonType;
  size?: ButtonSize;
  glyph?: Glyph;
  isLoading?: boolean;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}>;

const createClassNames = (
  type: ButtonType,
  size: ButtonSize,
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>
) =>
  classNames(styles.button, buttonProps?.className, {
    [styles.primary]: type === "primary",
    [styles.secondary]: type === "secondary",
    [styles.destructive]: type === "destructive",
    [styles.default]: size === "default",
    [styles.small]: size === "small",
    [styles.disabled]: buttonProps?.disabled,
  });

const Button = ({
  type = "primary",
  size = "default",
  glyph,
  isLoading = false,
  buttonProps,
  children,
}: Props) => {
  const getLoadingSpinnerTheme = (): Theme => {
    switch (type) {
      case "primary":
      case "secondary":
        return "dark";
      case "destructive":
        return "light";
    }
  };

  return (
    <button
      type="button"
      {...buttonProps}
      disabled={buttonProps?.disabled || isLoading}
      className={createClassNames(type, size, buttonProps)}
    >
      {isLoading && (
        <LoadingSpinner
          className={styles.loadingSpinner}
          size="small"
          theme={getLoadingSpinnerTheme()}
        />
      )}
      {glyph && <Icon className={styles.icon} glyph={glyph} />}
      {children}
    </button>
  );
};

interface LinkButtonProps extends Props {
  linkProps: LinkProps;
}

export const LinkButton = ({
  type = "primary",
  size = "default",
  glyph,
  buttonProps,
  linkProps,
  children,
}: LinkButtonProps) => (
  <Link {...linkProps} className={createClassNames(type, size, buttonProps)}>
    {glyph && <Icon glyph={glyph} className={styles.icon} />}
    {children}
  </Link>
);

export default Button;
