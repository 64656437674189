import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./DataTable.module.css";

type Props = PropsWithChildren<{
  head: ReactNode;
  classNames?: {
    tableWrapper?: string;
    table?: string;
  };
}>;

const DataTable = ({ head, classNames: stylesOverwrite, children }: Props) => (
  <div className={classNames(styles.wrapper, stylesOverwrite?.tableWrapper)}>
    <table className={classNames(styles.table, stylesOverwrite?.table)}>
      <thead>{head}</thead>
      {children}
    </table>
  </div>
);

export default DataTable;
