import {
  FieldError,
  RegisterOptions,
  UseFormRegisterReturn,
} from "react-hook-form";
import { FormPeriod } from "../types";
import { getMaxOptionalDate, getMinOptionalDate } from "../utils";
import styles from "./PeriodSelect.module.css";
import FormField from "./ui/FormField";
import MonthInput from "./ui/MonthInput";

interface FieldProps {
  error: FieldError | undefined;
  register: (opts: RegisterOptions) => UseFormRegisterReturn;
  min?: string;
  max?: string;
}

interface Props {
  period: FormPeriod | undefined;
  startProps: FieldProps;
  endProps: FieldProps;
}

const PeriodSelect = ({ period, startProps, endProps }: Props) => {
  const end = period?.end && period.end !== "" ? period.end : undefined;
  const start = period?.start && period.start !== "" ? period.start : undefined;
  const isEndOptional = !endProps.max;

  return (
    <div className={styles.periodSelect}>
      <FormField label="Von" direction="column" error={startProps.error}>
        {({ labelId, isOptional, isInvalid }) => (
          <MonthInput
            id={labelId}
            defaultValue={period?.start}
            isInvalid={isInvalid}
            min={startProps.min}
            max={getMinOptionalDate(end, startProps.max)}
            required={!isOptional}
            register={startProps.register}
          />
        )}
      </FormField>
      <FormField
        label="Bis"
        direction="column"
        error={endProps.error}
        isOptional={isEndOptional}
      >
        {({ labelId, isOptional, isInvalid }) => (
          <MonthInput
            id={labelId}
            defaultValue={period?.end}
            isInvalid={isInvalid}
            min={getMaxOptionalDate(start, endProps.min)}
            max={endProps.max}
            required={!isOptional}
            register={endProps.register}
          />
        )}
      </FormField>
    </div>
  );
};

export default PeriodSelect;
