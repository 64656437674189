import { forwardRef } from "react";
import { MD } from "../http/monatsdatenApi";
import i18n from "../i18n";
import Select from "./ui/Select";

type Props = JSX.IntrinsicElements["select"] & { isInvalid?: boolean };

const CompanyTypeSelect = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  return (
    <Select {...props} ref={ref}>
      <option></option>
      {MD.companyTypes.map((type) => (
        <option key={type} value={type}>
          {i18n.getCompanyType(type)}
        </option>
      ))}
    </Select>
  );
});

CompanyTypeSelect.displayName = "CompanyTypeSelect";

export default CompanyTypeSelect;
