import useFormError from "../hooks/useFormError";
import { MD } from "../http/monatsdatenApi";
import { processFormPeriod } from "../utils";
import VersionForm, { VersionFormData } from "./VersionForm";
import ModalDialog from "./ui/ModalDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  minDate?: string;
  onSubmit: (body: MD.PostVersionBody) => Promise<MD.Version>;
  onSuccess: (version: MD.Version) => void;
}

const CreateVersionModal = ({
  isOpen,
  onClose,
  minDate,
  onSubmit,
  onSuccess,
}: Props) => {
  const { handleSubmit, handleClose, error } = useFormError(onSubmit, onClose);

  return (
    <ModalDialog
      title="Version erstellen"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <VersionForm
        error={error}
        onSubmit={(formData) =>
          handleSubmit(processVersionFormData(formData), onSuccess)
        }
        onAbort={handleClose}
        minDate={minDate}
      />
    </ModalDialog>
  );
};

const processVersionFormData = ({
  type,
  period,
}: VersionFormData): MD.PostVersionBody => ({
  type,
  period: processFormPeriod(period),
});

export default CreateVersionModal;
