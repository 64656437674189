import useSWRImmutable from "swr/immutable";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import { FetcherOptions, PaginationQuery } from "../types";

export namespace MD {
  interface Error {
    code: number;
    message: string;
    reason?: string;
  }

  export interface ErrorResponse {
    error: Error;
  }

  type MemberId = number;

  export type CompanyId = number;

  export interface Company {
    id: CompanyId;
    name: string;
    memberId: MemberId;
    memberName: string;
    permissions: string[];
  }

  export const companyTypes = <const>[
    "pure-lodging",
    "pure-non-lodging",
    "mixed-business",
    "camping",
  ];

  export type CompanyType = (typeof companyTypes)[number];

  export interface Period {
    start: string;
    end?: string;
  }

  export interface Version {
    number: number;
    type: CompanyType;
    period: Period;
  }

  export interface PostVersionBody {
    period: Period;
    type: CompanyType;
  }

  export interface PutVersionBody {
    period: Period;
  }

  interface LodgingData {
    adultBeds?: number;
    extraBeds?: number;
    openDays?: number;
  }

  type FacilitiesSource = "user" | "system";

  type LodgingDataWithSources = LodgingData & {
    adultBedsSrc?: FacilitiesSource;
    extraBedsSrc?: FacilitiesSource;
  };

  interface NonLodgingData {
    seats?: number;
    openDays?: number;
  }

  type NonLodgingDataWithSource = NonLodgingData & {
    seatsSrc?: FacilitiesSource;
  };

  interface CampingData {
    pitches?: number;
    leasedPitches?: number;
    openDays?: number;
  }

  type CampingDataWithSource = CampingData & {
    pitchesSrc?: FacilitiesSource;
  };

  interface OvernightStays {
    individualGuests?: number;
    mediatedGuests?: number;
    guestsWithDiscount?: number;
    children?: number;
    others?: number;
    adultsFree?: number;
    childrenWithDiscount?: number;
    overnights?: number;
    bedAndBreakfast?: number;
    halfBoard?: number;
    fullBoard?: number;
  }

  export type OvernightStaysWithPreviousYearSum = OvernightStays & {
    previousYearSum?: number;
  };

  export interface PutMonthlyDataBody {
    date: string;
    lodgingData?: LodgingData;
    nonLodgingData?: NonLodgingData;
    campingData?: CampingData;
    overnightStays?: OvernightStays;
  }

  export interface MonthlyData {
    date: string;
    lodgingData?: LodgingDataWithSources;
    nonLodgingData?: NonLodgingDataWithSource;
    campingData?: CampingDataWithSource;
    overnightStays?: OvernightStaysWithPreviousYearSum;
  }

  export type ExternalOpenDaysSource = "tagesinkassobuch";

  export interface ExternalOpenDaysSourceWithPeriod {
    source: ExternalOpenDaysSource;
    period: Period;
  }

  export interface UseMonthlyDataResponse {
    items: MonthlyData[];
    externalOpenDaysSources?: ExternalOpenDaysSourceWithPeriod[];
    alertDismissed?: boolean;
  }
}

const api = wretch()
  .addon(QueryStringAddon)
  .options({ credentials: "include" })
  .url("/api/v1");

const fetcher = <Response>({ url, query, signal }: FetcherOptions) => {
  return api
    .options({ signal })
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();
};

export const parseErrorResponse = (error: Error): MD.ErrorResponse =>
  JSON.parse(error.message);

export const useCompanies = (query: PaginationQuery, signal: AbortSignal) =>
  useSWRImmutable({ url: `/companies`, query, signal }, (opts) =>
    fetcher<{ items: MD.Company[] }>(opts)
  );

export const useCompany = (id: MD.CompanyId, signal: AbortSignal) =>
  useSWRImmutable(id > 0 ? { url: `/companies/${id}`, signal } : null, (opts) =>
    fetcher<MD.Company>(opts)
  );

export const useVersions = (companyId: MD.CompanyId) =>
  useSWRImmutable({ url: `/companies/${companyId}/versions` }, (opts) =>
    fetcher<MD.Version[]>(opts)
  );

export const postVersion = (
  companyId: MD.CompanyId,
  body: MD.PostVersionBody
) => api.url(`/companies/${companyId}/versions`).post(body).json<MD.Version>();

export const putVersion = (
  companyId: MD.CompanyId,
  versionNumber: number,
  body: MD.PutVersionBody
) =>
  api
    .url(`/companies/${companyId}/versions/${versionNumber}`)
    .put(body)
    .json<MD.Version>();

export const deleteVersion = (companyId: MD.CompanyId, versionNumber: number) =>
  api.url(`/companies/${companyId}/versions/${versionNumber}`).delete().res();

export interface UseMonthlyDataKey {
  url: string;
  query: { year: number };
}

export const getUseMonthlyDataKey = (
  companyId: MD.CompanyId,
  versionNumber: number,
  year: number
): UseMonthlyDataKey => ({
  url: `/companies/${companyId}/versions/${versionNumber}/monthly_data`,
  query: { year },
});

export const useMonthlyData = (
  companyId: MD.CompanyId,
  versionNumber: number,
  year: number
) =>
  useSWRImmutable(
    getUseMonthlyDataKey(companyId, versionNumber, year),
    (opts) => fetcher<MD.UseMonthlyDataResponse>(opts)
  );

export const putMonthlyData = (
  companyId: MD.CompanyId,
  versionNumber: number,
  body: MD.PutMonthlyDataBody
) =>
  api
    .url(`/companies/${companyId}/versions/${versionNumber}/monthly_data`)
    .put(body)
    .res();

export const dismissAlert = (
  companyId: MD.CompanyId,
  versionNumber: number,
  year: number
) =>
  api
    .url(
      `/companies/${companyId}/versions/${versionNumber}/monthly_data:dismissAlert`
    )
    .post({ year })
    .res();
