import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form";
import { formatYearMonth } from "../../utils";
import Input from "./Input";

type Props = JSX.IntrinsicElements["input"] & {
  defaultValue?: string;
  isInvalid?: boolean;
  min?: string;
  max?: string;
  register: (opts: RegisterOptions) => UseFormRegisterReturn;
};

const MonthInput = ({
  defaultValue,
  min,
  max,
  register,
  required,
  ...props
}: Props) => {
  const formattedMin = min ? formatYearMonth(new Date(min)) : undefined;
  const formattedMax = max ? formatYearMonth(new Date(max)) : undefined;

  return (
    <Input
      placeholder={"yyyy-MM"} // for browsers that don't support type="month"
      {...props}
      type="month"
      defaultValue={
        defaultValue ? formatYearMonth(new Date(defaultValue)) : undefined
      }
      min={formattedMin}
      max={formattedMax}
      {...register({
        required,
        ...(formattedMin && {
          min: {
            value: formattedMin,
            message:
              "Das Feld enthält einen ungültigen Wert. Geben Sie ein späteres Datum ein.",
          },
        }),
        ...(formattedMax && {
          max: {
            value: formattedMax,
            message:
              "Das Feld enthält einen ungültigen Wert. Geben Sie ein früheres Datum ein.",
          },
        }),
        pattern: /([0-9]){4}-(0?[1-9]|1[0-2])/, // yyyy-MM or yyyy-M
      })}
    />
  );
};

export default MonthInput;
