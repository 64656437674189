import { MD } from "../http/monatsdatenApi";
import { FormPeriod } from "../types";
import CompanyTypeSelect from "./CompanyTypeSelect";
import PeriodSelect from "./PeriodSelect";
import Button from "./ui/Button";
import Form from "./ui/Form";
import FormField from "./ui/FormField";

export interface VersionFormData {
  type: MD.CompanyType;
  period: FormPeriod;
}

interface Props {
  defaultValues?: VersionFormData;
  error?: MD.ErrorResponse;
  minDate?: string;
  maxDate?: string;
  showCompanyTypeSelect?: boolean;
  onSubmit: (period: VersionFormData) => void;
  onAbort: () => void;
}

const VersionForm = ({
  defaultValues,
  error,
  minDate,
  maxDate,
  showCompanyTypeSelect = true,
  onSubmit,
  onAbort,
}: Props) => (
  <Form<VersionFormData>
    defaultValues={defaultValues}
    error={error}
    onSubmit={onSubmit}
    secondaryButton={
      <Button
        type="secondary"
        buttonProps={{
          onClick: onAbort,
        }}
      >
        Abbrechen
      </Button>
    }
  >
    {({ register, watch, formState: { errors } }) => {
      const period = watch("period");

      return (
        <>
          {showCompanyTypeSelect && (
            <FormField
              label="Betriebsart"
              direction="column"
              error={errors.type}
            >
              {({ labelId, isOptional, isInvalid }) => (
                <CompanyTypeSelect
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register("type", {
                    required: !isOptional,
                  })}
                />
              )}
            </FormField>
          )}
          <PeriodSelect
            period={period}
            startProps={{
              error: errors.period?.start,
              register: (opts) => register("period.start", opts),
              min: minDate,
              max: maxDate,
            }}
            endProps={{
              error: errors.period?.end,
              register: (opts) => register("period.end", opts),
              min: minDate,
              max: maxDate,
            }}
          />
        </>
      );
    }}
  </Form>
);

export default VersionForm;
