import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MD,
  useCompanies,
  useCompany as useSPCompany,
} from "../http/monatsdatenApi";
import { RemoteDataStatus } from "../types";
import { getRemoteDataStatus, parseIntParam } from "../utils";

type CompanyContextType = {
  company?: MD.Company;
  hasOnlyOneCompany: boolean;
  status: RemoteDataStatus;
  setCompanyId: (companyId?: MD.CompanyId) => void;
};

const CompanyContext = createContext<CompanyContextType>(undefined!);

const LOCAL_STORAGE_KEY_COMPANY = "selected-company";

export const CompanyProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { companyId: companyIdParam } = useParams();
  const localStorageCompanyId = localStorage.getItem(LOCAL_STORAGE_KEY_COMPANY);
  const [companyId, setCompanyId] = useState<MD.CompanyId | undefined>(
    parseIntParam(companyIdParam)
  );

  const companyListAborter = useRef(new AbortController());
  const {
    data,
    isValidating: isCompanyListValidating,
    error: companyListError,
  } = useCompanies({ page: 1, limit: 2 }, companyListAborter.current.signal);
  const firstCompany = data?.items[0];
  const companyListStatus = getRemoteDataStatus({
    isValidating: isCompanyListValidating,
    error: companyListError,
  });

  const aborter = useRef(new AbortController());
  const {
    data: company,
    isValidating: isCompanyValidating,
    error: companyError,
  } = useSPCompany(companyId ?? 0, aborter.current.signal);
  const companyStatus = getRemoteDataStatus({
    isValidating: isCompanyValidating,
    error: companyError,
  });

  useEffect(
    () =>
      companyId
        ? localStorage.setItem(LOCAL_STORAGE_KEY_COMPANY, companyId.toString())
        : localStorage.removeItem(LOCAL_STORAGE_KEY_COMPANY),
    [companyId]
  );

  const value: CompanyContextType = {
    company,
    hasOnlyOneCompany: data?.items.length === 1,
    status: mergeStatuses([companyListStatus, companyStatus]),
    setCompanyId: (companyId?: MD.CompanyId) => {
      aborter.current.abort();
      aborter.current = new AbortController();
      setCompanyId(companyId);
      companyId ? navigate(`/${companyId}`) : navigate("/");
    },
  };

  useEffect(() => {
    if (companyId) return;
    value.setCompanyId(
      localStorageCompanyId ? parseInt(localStorageCompanyId) : firstCompany?.id
    );
  }, [companyId, firstCompany]);

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};

const mergeStatuses = (statuses: RemoteDataStatus[]): RemoteDataStatus => {
  if (statuses.includes("failure")) return "failure";
  if (statuses.includes("validating")) return "validating";
  return "success";
};

export const useCompany = () => {
  return useContext(CompanyContext);
};
