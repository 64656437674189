import { useEffect, useState } from "react";
import { MD } from "../http/monatsdatenApi";
import {
  byStartDateAsc,
  formatLongMonth,
  getLastOfMonth,
  includesYear,
} from "../utils";
import VersionData from "./VersionData";
import Tabs from "./ui/Tabs";

interface Props {
  companyId: MD.CompanyId;
  versions: MD.Version[];
  year: number;
}

const YearData = ({ companyId, versions, year }: Props) => {
  const yearVersions = versions
    .filter(({ period }) => includesYear(period, year))
    .sort(byStartDateAsc);
  const [selectedTab, setSelectedTab] = useState(yearVersions[0]?.number);

  useEffect(() => {
    setSelectedTab(yearVersions[0]?.number);
  }, [year]);

  return (
    <>
      {yearVersions.length > 1 && (
        <Tabs>
          {yearVersions.map(({ number, period }) => (
            <Tabs.Tab
              key={number}
              text={periodToString(period, year)}
              isSelected={selectedTab === number}
              onClick={() => setSelectedTab(number)}
            />
          ))}
        </Tabs>
      )}

      {yearVersions.map(
        (v) =>
          selectedTab === v.number && (
            <VersionData
              key={v.number}
              companyId={companyId}
              version={v}
              year={year}
            />
          )
      )}
    </>
  );
};

const periodToString = ({ start, end }: MD.Period, year: number) => {
  const includeStart = new Date(start).getFullYear() === year;
  const includeEnd = end && new Date(end).getFullYear() === year;

  if (includeStart && includeEnd)
    return `${formatLongMonth(new Date(start))} – ${formatLongMonth(
      new Date(end)
    )}`;

  if (includeStart) return `Ab ${formatLongMonth(new Date(start))}`;

  if (includeEnd) return `Bis ${formatLongMonth(new Date(end))}`;

  return `${formatLongMonth(new Date(year, 0))} – ${formatLongMonth(
    getLastOfMonth(new Date(year, 11))
  )}`;
};

export default YearData;
