import classNames from "classnames";
import { formatAsNumber, formatAsPercent } from "../../utils";
import SvgWarningAmber from "../icons/WarningAmber";
import styles from "./CalculatedDataCell.module.css";
import Icon from "./Icon";

interface Props {
  className?: string;
  format?: "number" | "percentage";
  value?: number;
  isInvalid?: boolean;
  title?: string;
}

const CalculatedDataCell = ({
  className,
  format = "number",
  value,
  isInvalid = false,
  title,
}: Props) => (
  <td
    className={classNames(styles.cell, className, {
      [styles.invalid]: isInvalid,
    })}
    title={title}
  >
    <div className={styles.cellContent}>
      {isInvalid && <Icon glyph={SvgWarningAmber} className={styles.icon} />}
      {value &&
        (format === "percentage" ? (
          <Percentage value={value} />
        ) : (
          formatAsNumber(value)
        ))}
    </div>
  </td>
);

interface PercentageProps {
  value: number;
}

const Percentage = ({ value }: PercentageProps) => (
  <span
    className={classNames({
      [styles.zero]: value === 0,
      [styles.negative]: value < 0,
    })}
  >
    {formatAsPercent(2, value)}
  </span>
);

export default CalculatedDataCell;
