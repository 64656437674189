import { MD } from "./http/monatsdatenApi";

const i18n = {
  title: "HGV Monatsdaten",
  getCompanyType: (type: MD.CompanyType) => {
    switch (type) {
      case "pure-lodging":
        return "Reiner Beherbergungsbetrieb";
      case "pure-non-lodging":
        return "Reiner Nichtbeherbergungsbetrieb";
      case "mixed-business":
        return "Mischbetrieb";
      case "camping":
        return "Campingbetrieb";
    }
  },
  errors: {
    periodOverlaps:
      "Der gewählte Zeitraum überschneidet sich mit einer bereits existierenden Version.",
    orphanData: "Der gewählte Zeitraum deckt nicht alle Daten der Version ab.",
    default: "Es ist ein unbekannter Fehler aufgetreten.",
  },
};

export default i18n;
