import { ReactNode } from "react";
import {
  DefaultValues,
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { MD } from "../../http/monatsdatenApi";
import i18n from "../../i18n";
import Button from "./Button";
import ErrorText from "./ErrorText";
import styles from "./Form.module.css";

type FormChildrenProps<TFieldValues extends FieldValues> =
  UseFormReturn<TFieldValues> & {
    disabled?: boolean;
  };

type Props<TFieldValues extends FieldValues> = {
  disabled?: boolean;
  error?: MD.ErrorResponse;
  secondaryButton?: ReactNode;
  onSubmit?: SubmitHandler<TFieldValues>;
  submitText?: ReactNode;
  defaultValues?: DefaultValues<TFieldValues>;
  children?:
    | ReactNode
    | ((useFormProps: FormChildrenProps<TFieldValues>) => ReactNode);
};

const Form = <TFieldValues extends FieldValues>({
  children,
  disabled,
  error,
  secondaryButton,
  submitText = "Speichern",
  defaultValues,
  onSubmit,
}: Props<TFieldValues>) => {
  const useFormProps = useForm<TFieldValues>({
    defaultValues,
    mode: "onBlur",
  });
  const { isSubmitting } = useFormProps.formState;

  return (
    <form
      onSubmit={(e) => {
        if (onSubmit) {
          e.stopPropagation();
          useFormProps.handleSubmit(onSubmit)(e);
        }
      }}
    >
      {typeof children === "function"
        ? children({ ...useFormProps, disabled })
        : children}

      {error && (
        <div className={styles.error}>
          <ErrorText text={getErrorMessage(error)} />
        </div>
      )}

      {onSubmit && (
        <div className={styles.submitBar}>
          {secondaryButton}
          <Button
            type="primary"
            isLoading={isSubmitting}
            buttonProps={{
              type: "submit",
              disabled: disabled || isSubmitting,
            }}
          >
            {submitText}
          </Button>
        </div>
      )}
    </form>
  );
};

const getErrorMessage = ({ error: { reason } }: MD.ErrorResponse): string => {
  switch (reason) {
    case "PERIOD_OVERLAPS_VERSION_PERIOD":
      return i18n.errors.periodOverlaps;
    case "VERSION_HAS_ORPHAN_DATA":
      return i18n.errors.orphanData;
    default:
      return i18n.errors.default;
  }
};

export default Form;
