import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "../context/authContext";
import { CompanyProvider } from "../context/companyContext";
import NotificationDispatch, {
  useNotification,
} from "../context/notificationContext";
import styles from "./App.module.css";
import Home from "./Home";
import Notification from "./ui/Notification";

const App = () => {
  const [{ show, severity, message }, dispatch] = useNotification();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationDispatch.Provider value={dispatch}>
          <div className={styles.app}>
            <RequireAuth>
              <Routes>
                <Route
                  path=":companyId?/*"
                  element={
                    <CompanyProvider>
                      <Home />
                    </CompanyProvider>
                  }
                />
              </Routes>
            </RequireAuth>
          </div>
          {show && <Notification type={severity} text={message ?? ""} />}
        </NotificationDispatch.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
