import { useEffect, useId } from "react";
import { useSearchParams } from "react-router-dom";
import { useCompany } from "../context/companyContext";
import { MD, useVersions } from "../http/monatsdatenApi";
import { Permission } from "../types";
import {
  getRemoteDataStatus,
  parseIntParam,
  range,
  removeDuplicates,
} from "../utils";
import styles from "./DataPage.module.css";
import YearData from "./YearData";
import YearSelect from "./YearSelect";
import SvgSettings from "./icons/Settings";
import { LinkButton } from "./ui/Button";
import ErrorText from "./ui/ErrorText";
import LoadingSpinner from "./ui/LoadingSpinner";
import Page from "./ui/Page";

interface Props {
  companyId: MD.CompanyId;
}

const YEAR_PARAM_KEY = "jahr";

const DataPage = ({ companyId }: Props) => {
  const { company } = useCompany();
  const canWriteData = company?.permissions.includes(Permission.DataWrite);
  const canWriteSettings = company?.permissions.includes(
    Permission.SettingsWrite
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const year = parseIntParam(searchParams.get(YEAR_PARAM_KEY));

  const { data, isValidating, error } = useVersions(companyId);
  const versions = data ?? [];
  const status = getRemoteDataStatus({ isValidating, error });

  const yearSelectId = useId();

  const years = removeDuplicates(
    versions.reduce((state: number[], value) => {
      const start = new Date(value.period.start).getFullYear();
      const end = value.period.end
        ? new Date(value.period.end).getFullYear()
        : Infinity;
      const currentYear = new Date().getFullYear();
      const periodYears = range(start, Math.min(end, currentYear));
      return [...state, ...periodYears];
    }, [])
  ).sort((a, b) => b - a);
  const latestYear = years[0];

  const setYearParam = (value: number) => {
    searchParams.set(YEAR_PARAM_KEY, String(value));
    setSearchParams(searchParams);
  };

  // Default to latest year
  useEffect(() => {
    if (year || !latestYear) return;
    setYearParam(latestYear);
  }, [year, latestYear]);

  return (
    <Page>
      <div className={styles.page}>
        {!canWriteData ? (
          <div className={styles.status}>
            Sie verfügen nicht über die notwendigen Berechtigungen, um auf die
            Monatsdaten des Betriebes zuzugreifen.
            {canWriteSettings && <SettingsButton text="Einstellungen" />}
          </div>
        ) : (
          <>
            {status === "success" && (
              <>
                {versions.length === 0 ? (
                  <div className={styles.status}>
                    Die Konfiguration des Betriebes wurde noch nicht vollständig
                    abgeschlossen.
                    {canWriteSettings && (
                      <SettingsButton text="Betrieb konfigurieren" />
                    )}
                  </div>
                ) : (
                  <>
                    <div className={styles.header}>
                      <div className={styles.filter}>
                        <label htmlFor={yearSelectId}>Jahr</label>
                        <YearSelect
                          id={yearSelectId}
                          options={years}
                          value={year}
                          onChange={(updated) =>
                            updated && setYearParam(updated)
                          }
                        />
                      </div>
                      {canWriteSettings && (
                        <SettingsButton text="Einstellungen" />
                      )}
                    </div>

                    {year && (
                      <YearData
                        companyId={companyId}
                        versions={versions}
                        year={year}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {status === "validating" && (
          <div className={styles.status}>
            <LoadingSpinner />
          </div>
        )}

        {status === "failure" && (
          <div className={styles.status}>
            <ErrorText text="Fehler beim Laden der Monatsdaten." />
          </div>
        )}
      </div>
    </Page>
  );
};

interface SettingsButtonProps {
  text: string;
}

const SettingsButton = ({ text }: SettingsButtonProps) => (
  <LinkButton glyph={SvgSettings} linkProps={{ to: "einstellungen" }}>
    {text}
  </LinkButton>
);

export default DataPage;
