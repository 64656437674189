import { MD } from "../http/monatsdatenApi";
import { formatISODate, getLongMonthName, months } from "../utils";
import styles from "./NonLodgingTable.module.css";
import OpenDaysDataCell from "./OpenDaysDataCell";
import { ExternalOpenDaysSourceByMonth } from "./VersionData";
import CalculatedDataCell from "./ui/CalculatedDataCell";
import DataCell from "./ui/DataCell";
import DataTable from "./ui/DataTable";

interface Props {
  year: number;
  activeMonths: number[];
  monthlyData: MD.MonthlyData[];
  externalOpenDaysSources?: ExternalOpenDaysSourceByMonth;
  highlightCopiedFacilities?: boolean;
  onChange: (body: MD.PutMonthlyDataBody) => void;
}

type MonthlyDataByMonth = { [month: number]: MD.MonthlyData };

const NonLodgingTable = ({
  year,
  activeMonths,
  monthlyData,
  externalOpenDaysSources,
  highlightCopiedFacilities = false,
  onChange,
}: Props) => {
  const monthlyDataByMonth = monthlyData.reduce(
    (state: MonthlyDataByMonth, value) => {
      const month = new Date(value.date).getMonth();
      state[month] = value;
      return state;
    },
    {}
  );

  return (
    <DataTable
      classNames={{ table: styles.table }}
      head={
        <tr>
          <th></th>
          {months.map((m) => (
            <th key={m} className={styles.month}>
              {getLongMonthName(m)}
            </th>
          ))}
          <th>Summe</th>
        </tr>
      }
    >
      <tbody>
        <tr>
          <th>Sitzplätze</th>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <DataCell
                key={m}
                className={styles.month}
                value={md?.nonLodgingData?.seats}
                isDisabled={!activeMonths.includes(m)}
                isHighlighted={
                  highlightCopiedFacilities &&
                  md?.nonLodgingData?.seatsSrc === "system"
                }
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    nonLodgingData: {
                      ...md?.nonLodgingData,
                      seats: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell></CalculatedDataCell>
        </tr>
        <tr>
          <th>Offenhaltetage</th>
          {months.map((m) => {
            const md = monthlyDataByMonth[m];
            return (
              <OpenDaysDataCell
                key={m}
                className={styles.month}
                source={externalOpenDaysSources?.[m]}
                value={md?.nonLodgingData?.openDays}
                isActive={activeMonths.includes(m)}
                saveChanges={(value) =>
                  onChange({
                    ...md,
                    date: formatISODate(new Date(year, m)),
                    nonLodgingData: {
                      ...md?.nonLodgingData,
                      openDays: value,
                    },
                  })
                }
              />
            );
          })}
          <CalculatedDataCell
            value={Object.values(monthlyDataByMonth).reduce(
              (state: number | undefined, value) => {
                if (!value.nonLodgingData?.openDays) return state;
                return (state ?? 0) + value.nonLodgingData?.openDays;
              },
              undefined
            )}
          />
        </tr>
      </tbody>
    </DataTable>
  );
};

export default NonLodgingTable;
