import Select from "./ui/Select";

interface Props {
  id: string;
  options: number[];
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const YearSelect = ({ id, options, value, onChange }: Props) => (
  <Select id={id} value={value} onChange={(e) => onChange(+e.target.value)}>
    {/* Default to empty option when the options do not include the passed value */}
    {value && !options.includes(value) && <option></option>}
    {options.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ))}
  </Select>
);

export default YearSelect;
