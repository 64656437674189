import { useState } from "react";
import { WretchResponse } from "wretch";
import { MD } from "../http/monatsdatenApi";
import { periodToString } from "../utils";
import ModalDialog from "./ui/ModalDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  version: MD.Version;
  onConfirm: () => Promise<WretchResponse>;
  onSuccess: () => void;
  onFailure: (error: unknown) => void;
}

const DeleteVersionModal = ({
  isOpen,
  onClose,
  version,
  onConfirm,
  onSuccess,
  onFailure,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <ModalDialog
      title="Version löschen"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          title: "Abbrechen",
          type: "secondary",
          onClick: onClose,
        },
        {
          title: "Löschen",
          type: "destructive",
          isLoading: isSubmitting,
          onClick: () => {
            setIsSubmitting(true);
            onConfirm()
              .then(onSuccess)
              .catch(onFailure)
              .finally(() => setIsSubmitting(false));
          },
        },
      ]}
    >
      <p>
        Möchten Sie wirklich die Version{" "}
        <strong>{periodToString(version.period, { lowercase: true })}</strong>{" "}
        und alle zugehörigen Daten löschen?
        <br />
        Diese Aktion kann nicht rückgängig gemacht werden.
      </p>
    </ModalDialog>
  );
};

export default DeleteVersionModal;
