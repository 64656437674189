import classNames from "classnames";
import { PropsWithChildren, useContext } from "react";
import { WretchResponse } from "wretch";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../context/notificationContext";
import useToggle from "../hooks/useToggle";
import {
  MD,
  deleteVersion,
  postVersion,
  putVersion,
  useVersions,
} from "../http/monatsdatenApi";
import i18n from "../i18n";
import {
  byStartDateDesc,
  formatISODate,
  formatYearMonth,
  getRemoteDataStatus,
  periodToString,
} from "../utils";
import CreateVersionModal from "./CreateVersionModal";
import DeleteVersionModal from "./DeleteVersionModal";
import EditVersionModal from "./EditVersionModal";
import styles from "./Settings.module.css";
import SvgAdd from "./icons/Add";
import SvgDelete from "./icons/Delete";
import SvgEdit from "./icons/Edit";
import ErrorText from "./ui/ErrorText";
import Icon from "./ui/Icon";
import LoadingSpinner from "./ui/LoadingSpinner";
import Page from "./ui/Page";
import Table from "./ui/Table";
import TableButton from "./ui/TableButton";

interface Props {
  companyId: MD.CompanyId;
}

const Settings = ({ companyId }: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const { data, isValidating, error, mutate } = useVersions(companyId);
  const versions = data ?? [];
  const status = getRemoteDataStatus({ isValidating, error });
  const createVersionModal = useToggle();

  versions.sort(byStartDateDesc);

  const latestVersion = versions[0];

  const updateVersions = (updated: MD.Version[]) =>
    mutate(updated, { revalidate: false });

  return (
    <Page title="Einstellungen" backLinkText="Zurück zur Datenübersicht">
      {status === "success" && (
        <>
          <Table
            total={versions.length}
            head={
              <tr>
                <th className={styles.alignLeft}>Zeitraum</th>
                <th className={styles.alignLeft}>Betriebsart</th>
                <th className={styles.narrow}></th>
                <th className={styles.narrow}></th>
              </tr>
            }
            body={versions.map((v, index, arr) => {
              const prevEnd = arr[index + 1]?.period.end;
              const nextStart = arr[index - 1]?.period.start;
              const minDate = prevEnd
                ? addMonths(new Date(prevEnd), 1)
                : undefined;
              const maxDate = nextStart
                ? addMonths(new Date(nextStart), -1)
                : undefined;

              return (
                <VersionRow
                  key={v.number}
                  version={v}
                  minDate={minDate ? formatYearMonth(minDate) : undefined}
                  maxDate={maxDate ? formatYearMonth(maxDate) : undefined}
                  editVersion={(body) => putVersion(companyId, v.number, body)}
                  onEditSuccess={(edited) =>
                    updateVersions(
                      versions.map((v) =>
                        v.number === edited.number ? edited : v
                      )
                    )
                  }
                  deleteVersion={() => deleteVersion(companyId, v.number)}
                  onDeleteSuccess={() =>
                    updateVersions(
                      versions.filter(({ number }) => number !== v.number)
                    )
                  }
                />
              );
            })}
          />

          <button
            className={styles.createVersionButton}
            onClick={createVersionModal.open}
          >
            <Icon glyph={SvgAdd} className={styles.icon} />
            Version erstellen
          </button>

          <CreateVersionModal
            isOpen={createVersionModal.isOpen}
            onClose={createVersionModal.close}
            minDate={
              latestVersion?.period.end
                ? formatISODate(addDay(new Date(latestVersion.period.end)))
                : undefined
            }
            onSubmit={(body) => postVersion(companyId, body)}
            onSuccess={(inserted) => {
              updateVersions([...versions, inserted]);
              dispatch(
                showSuccessNotification("Version erfolgreich erstellt.")
              );
              createVersionModal.close();
            }}
          />
        </>
      )}

      {status === "validating" && (
        <div className={styles.status}>
          <LoadingSpinner />
        </div>
      )}

      {status === "failure" && (
        <div className={styles.status}>
          <ErrorText text="Fehler beim Laden der Monatsdaten." />
        </div>
      )}
    </Page>
  );
};

interface VersionRowProps {
  version: MD.Version;
  minDate?: string;
  maxDate?: string;
  editVersion: (body: MD.PutVersionBody) => Promise<MD.Version>;
  onEditSuccess: (edited: MD.Version) => void;
  deleteVersion: () => Promise<WretchResponse>;
  onDeleteSuccess: () => void;
}

const VersionRow = ({
  version,
  minDate,
  maxDate,
  editVersion,
  onEditSuccess,
  deleteVersion,
  onDeleteSuccess,
}: VersionRowProps) => {
  const dispatch = useContext(NotificationDispatch);
  const editModal = useToggle();
  const deleteModal = useToggle();

  return (
    <tr>
      <td>{periodToString(version.period)}</td>
      <td>{i18n.getCompanyType(version.type)}</td>
      <TableButtonCell>
        <TableButton glyph={SvgEdit} onClick={editModal.open} />
        <EditVersionModal
          isOpen={editModal.isOpen}
          onClose={editModal.close}
          version={version}
          minDate={minDate}
          maxDate={maxDate}
          onSubmit={editVersion}
          onSuccess={(edited) => {
            onEditSuccess(edited);
            dispatch(
              showSuccessNotification("Version erfolgreich bearbeitet.")
            );
            editModal.close();
          }}
        />
      </TableButtonCell>
      <TableButtonCell>
        <TableButton glyph={SvgDelete} onClick={deleteModal.open} />
        <DeleteVersionModal
          isOpen={deleteModal.isOpen}
          onClose={deleteModal.close}
          version={version}
          onConfirm={deleteVersion}
          onSuccess={() => {
            onDeleteSuccess();
            dispatch(showSuccessNotification("Version erfolgreich gelöscht."));
            deleteModal.close();
          }}
          onFailure={(err) =>
            dispatch(
              showErrorNotification(err, "Fehler beim Löschen der Version.")
            )
          }
        />
      </TableButtonCell>
    </tr>
  );
};

const TableButtonCell = ({ children }: PropsWithChildren) => (
  <td className={classNames(styles.tableButtonCell, styles.narrow)}>
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  </td>
);

const addDay = (date: Date) => {
  date.setDate(date.getDate() + 1);
  return date;
};

const addMonths = (date: Date, summand: number) => {
  date.setDate(1);
  date.setMonth(date.getMonth() + summand);
  return date;
};

export default Settings;
