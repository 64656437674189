import { MD } from "../http/monatsdatenApi";
import CalculatedDataCell from "./ui/CalculatedDataCell";
import DataCell from "./ui/DataCell";

interface Props {
  className?: string;
  source: MD.ExternalOpenDaysSource | undefined;
  value: number | undefined;
  isActive?: boolean;
  saveChanges: (value: number | undefined) => void;
}

const OpenDaysDataCell = ({
  className,
  source,
  value,
  isActive = true,
  saveChanges,
}: Props) =>
  source ? (
    <CalculatedDataCell
      className={className}
      title={isActive ? `Quelle: ${sourceToString(source)}` : undefined}
      value={value}
    />
  ) : (
    <DataCell
      className={className}
      isDisabled={!isActive}
      value={value}
      saveChanges={saveChanges}
    />
  );

const sourceToString = (source: MD.ExternalOpenDaysSource) => {
  switch (source) {
    case "tagesinkassobuch":
      return "Tagesinkassobuch";
  }
};

export default OpenDaysDataCell;
