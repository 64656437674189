import classNames from "classnames";
import { PropsWithChildren, ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import styles from "./FormField.module.css";

interface Props {
  label: string;
  children:
    | ReactNode
    | ((props: {
        labelId: string;
        isOptional: boolean;
        disabled: boolean;
        isInvalid: boolean;
      }) => ReactNode);
  description?: ReactNode;
  helpText?: string;
  isOptional?: boolean;
  alignItemsRight?: boolean;
  disabled?: boolean;
  error?: FieldError;
  direction?: "column" | "row";
}

const FormField = ({
  label,
  description,
  helpText,
  children,
  isOptional = false,
  alignItemsRight = false,
  disabled = false,
  error,
  direction,
}: Props) => {
  const labelId = useId();
  const isInvalid = error !== undefined;

  return (
    <div
      className={classNames(styles.field, {
        [styles.disabled]: disabled,
        [styles.column]: direction === "column",
        [styles.row]: direction === "row",
      })}
    >
      <div className={styles.leftColumn}>
        <label htmlFor={labelId} className={styles.label}>
          {label}
          {isOptional && <span className={styles.optional}>(optional)</span>}
        </label>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div
        className={classNames(styles.rightColumn, {
          [styles.column]: direction === "column",
          [styles.alignRight]: alignItemsRight,
        })}
      >
        {typeof children === "function"
          ? children({
              labelId,
              isOptional,
              disabled,
              isInvalid,
            })
          : children}
        {isInvalid && <Error error={error} />}
        {helpText && <Help>{helpText}</Help>}
      </div>
    </div>
  );
};

const Help = ({ children }: PropsWithChildren) => (
  <p className={styles.helpText}>{children}</p>
);

const Error = ({ error }: { error: FieldError }) => (
  <p className={styles.error}>{getErrorMessage(error)}</p>
);

const getErrorMessage = ({ message, type }: FieldError): string => {
  if (message) return message;

  switch (type) {
    case "required":
      return "Das Feld muss einen Wert beinhalten.";
    case "min":
      return "Das Feld enthält keinen gültigen Wert, geben Sie einen größeren Wert ein.";
    case "max":
      return "Das Feld enthält keinen gültigen Wert, geben Sie einen kleineren Wert ein.";
    case "pattern":
      return "Der Wert hat ein ungültiges Format.";
    case "validate":
      return "Das Feld enthält keinen gültigen Wert.";
    default:
      return "";
  }
};

FormField.Error = Error;
FormField.Help = Help;

export default FormField;
