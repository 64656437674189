import { PropsWithChildren } from "react";
import SvgClose from "../icons/Close";
import styles from "./Alert.module.css";
import Icon from "./Icon";

type Props = PropsWithChildren<{
  onDismiss?: () => void;
}>;

const Alert = ({ onDismiss, children }: Props) => (
  <div className={styles.alert}>
    {children}
    {onDismiss && (
      <button className={styles.dismissButton} onClick={onDismiss}>
        <Icon glyph={SvgClose} className={styles.icon} />
      </button>
    )}
  </div>
);

export default Alert;
